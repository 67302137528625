export const LIVE_STAGE = {
  // 暂未开始
  NOT_STARTED: 'not_started',
  // 直播进行中
  ONGOING: 'ongoing',
  // 暂停直播
  PAUSED: 'paused',
  // 结束直播
  ENDED: 'ended',
};
