<template lang="pug">
  div#app(v-if="showqy")
    div#header
      comp-header
    div#content
      div#left.column
        comp-screen-share
        comp-live-setting
      div#center
        comp-live-stream
      div#right.column
        comp-participants
        comp-chat
    room-device-dialog(ref="roomDeviceDialog" @nextStep="showBeautyPresettingDialog")
    room-beauty-dialog(ref="roomBeautyDialog")
</template>

<script>
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import {
  SET_APP_INFO,
  SET_ROOM_ID,
  UPDATE_ROOM_NAME,
  UPDATE_USER_INFO,
} from 'constants/mutation-types';
// import {
//   sdkAppId,
//   expireTime,
//   secretKey,
//   anchorUserInfo,
//   roomInfo,
// } from '@/config/basic-info-config';
import compHeader from '@/components/comp-header';
import compScreenShare from '@/components/comp-screen-share';
import compLiveSetting from '@/components/comp-live-setting/index.vue';
import compLiveStream from '@/components/comp-live-stream';
import compParticipants from '@/components/comp-participants';
import compChat from '@/components/comp-chat';
import roomDeviceDialog from '@/components/comp-pre-setting/room-device-dialog.vue';
import roomBeautyDialog from '@/components/comp-pre-setting/room-beauty-dialog.vue';
export default {
  name: 'App',
  data() {
    return {
      showqy:false,// 参数不全就不显示页面 也不会初始化直播间
    }
  },
  components: {
    compHeader,
    compScreenShare,
    compLiveSetting,
    compLiveStream,
    compParticipants,
    compChat,
    roomBeautyDialog,
    roomDeviceDialog,
  },
  methods: {
    // 显示设置预设置弹窗
    showDevicePresettingDialog() {
      this.$refs.roomDeviceDialog.handleShowDeviceDialog();
    },
    // 显示美颜预设置弹窗
    showBeautyPresettingDialog() {
      this.$refs.roomBeautyDialog.handleShowBeautyDialog();
    },
    getRequest() { 
      var url = location.search; 
      var theRequest = new Object(); 
      if (url.indexOf("?") != -1) { 
          let str = url.substr(1); 
          let strs = str.split("&"); 
          for(let i = 0; i < strs.length; i++) { 
            theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]); 
          } 
      } 
      return theRequest; 
    },
    /**
     * url:http://localhost:8081/?roomId=1234&userSige=JyrVgrxCdZLrSjILEpVsjI0NTU1MjAw0AGLlqUWKVkpGekZKEH4xSnZiQUFmSlAdSYGBmZGRpbmFhCZzJTUvJLMtEywhtLi1KL4xLzkjPwimMbMdKC4WVpoibZ2pWNKoatTiXFQtmmeu29wanKidkakiXlVVJphkntEsWdosGOQiS1UY0lmLshVZiZGpgamJgYWtQCU3jNy&shareUserSig=eJw1jssOgjAURP*lWw25NpRX4sKFLgwbLWp0Q0op9EZ5pEVDNP67CLqcOXOSeZEk5o7qWzSKRAvGGAWA*dg*lCERoQ6QKdv8KtoW82HnAniUhn4wEcxV3WGBo2C1MCq9W2VSUUvdmL*O5UBncq2TDT9XTQPxxS*enT6Ep77EvVntNMv4Vhx9LeyNy*VP7LD6fvNcyoC5Abw-OeY3Dg__
     * 传参这几个参数 
     * 其中userSige和shareUserSig在后端通过userId生成 后端和前端的userId要保持一致
     * roomId
     * userSige
     * shareUserSig
     */
    // 设置TUIPusher配置信息
    handlePusherInfo() {
      var urlPara = this.getRequest();
      console.log(urlPara)
      // if (sdkAppId === '' || secretKey === '') {
      //   alert(`${this.$t('basic.Please configure your SDKAPPID')}\r\n\r\nconfig/basic-info-config.js`);
      // }
      // const generator = new LibGenerateTestUserSig(sdkAppId, secretKey, expireTime);
      // const userSig = generator.genTestUserSig(anchorUserInfo.userId);
      // const shareUserSig = generator.genTestUserSig(`share_${anchorUserInfo.userId}`);
      //后端生成shareUserSig
      const roomId = urlPara.roomId;
      const userSig  =  urlPara.userSig;
      const shareUserSig  =  urlPara.shareUserSig;
      const sdkAppId = Number(urlPara.sdkAppId);
      const roomName = urlPara.roomName; //乱码
      const userId = urlPara.userId;
      const userName = decodeURI(urlPara.userName); //乱码
      // const userName = "哈哈";
      localStorage.setItem("liveInfoId",urlPara.liveInfoId)
      localStorage.setItem("token",urlPara.token)
      console.log("参数------",roomId,userSig,shareUserSig,sdkAppId,roomName,userId,userName)
      
      if(!roomId||!userSig||!shareUserSig||!sdkAppId||!roomName||!userId||!userName){
        document.getElementsByTagName('body')[0].innerHTML="参数不全"+
        `!roomId:${roomId}!userSig:${userSig}!shareUserSig:${shareUserSig}!sdkAppId:${sdkAppId}!roomName:${roomName}!userId:${userId}!userName:${userName}`
        return
      }
      this.showqy = true; //参数校验通过 显示直播间
      // console.log(userSig)
      // console.log(shareUserSig)
      this.$store.commit(SET_APP_INFO, {
        sdkAppId,
        userSig,
        shareUserSig,
      });
      this.$store.commit(SET_ROOM_ID, Number(roomId));
      this.$store.commit(UPDATE_ROOM_NAME, roomName);
      this.$store.commit(UPDATE_USER_INFO, {
        userId: userId,
        userName: userName,
        // userAvatar: userAvatar,
      });
    },
    // 退出直播间
    async handleExit() {
      // 处理退出直播间
    },
    // 退出登录
    async handleLogout() {
      // 处理退出登录
    },
  },
  async created() {
    this.handlePusherInfo();
    this.$eventBus.$on('exit', this.handleExit);
    this.$eventBus.$on('logout', this.handleLogout);
  },
  mounted() {
    this.showDevicePresettingDialog();
  },
  beforeDestroy() {
    this.$eventBus.$off('exit', this.handleExit);
    this.$eventBus.$off('logout', this.handleLogout);
  },
};
</script>

<style lang="stylus">
@import '~assets/style/black-element-ui.styl';
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  --webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  width 100%
  height 100%
  position relative
  color $fontColor
  div#header
    width 100%
    height 50px
    background-color $backgroundColor
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.20);
  div#content
    position absolute
    top 50px
    width 100%
    left 0
    bottom 0
    display flex
    background-color $backgroundColor
    div#left
      width 20%
      min-width 300px
      max-width 406px
      height 100%
      background-color $themeColor
    div#center
      height 100%
      flex-grow 1
    div#right
      width 20%
      min-width 300px
      max-width 406px
      height 100%
      background-color $themeColor
    .column
      padding 8px
      display flex
      flex-direction column
      > div:not(:first-child)
        margin-top 8px
      > div:last-child
        flex-grow 1
</style>
